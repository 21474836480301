import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { Radio } from "antd"
import { backButton } from "@telegram-apps/sdk-react"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AppActions, AppTypes } from "@/redux/app"
import type { RadioChangeEvent } from "antd"
import { getAccountClicksHistory } from "@/services/api"
import * as ApiTypes from "@/services/api/types"
import Loading from "@/components/common/Loading"
import { amountFormat, amountFormatSplit, amountWithCommas, roundTo } from "@/utils"

type ClicksHistoryType = "day" | "hour" | "minute"
type MinuteDetails = {
  date: number
  clicks: number
}
type HourDetails = {
  date: number
  clicks: number
  details: MinuteDetails[]
}
type DayDetails = {
  date: number
  clicks: number
  details: HourDetails[]
}

const ClicksHistory = () => {
  const dispatch = useAppDispatch()
  const modalUserInfo = useAppSelector((state) => state.app.modalUserInfo)
  const [loading, setLoading] = useState(false)
  const [clicksHistory, setClicksHistory] = useState<DayDetails[]>([])
  const [visibleHistory, setVisibleHistory] = useState({ day: 0, hour: 0 })
  const clicksPeriods = {
    day: 86400 * 8, // setting is 15
    hour: 3600 * 8, // setting is 15
    minute: 60 * 8, // setting is 15
  }

  const fetchClickHistory = async () => {
    setLoading(true)
    const accountClicksHistoryList = await getAccountClicksHistory(modalUserInfo.id, "minute")
    const groupedList = groupClicksByDayHourMinute(accountClicksHistoryList)
    setClicksHistory(groupedList)
    setLoading(false)
  }

  useEffect(() => {
    fetchClickHistory()
  }, [])

  return (
    <div>
      {loading && <Loading />}
      {!loading && (
        <div>
          {clicksHistory.length > 0 && (
            <div>
              {clicksHistory.map((day) => {
                return (
                  <div
                    key={day.date}
                    className={classnames("xray-user-history", {
                      "xray-user-history-active": visibleHistory.day === day.date,
                    })}
                  >
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setVisibleHistory({ day: visibleHistory.day === day.date ? 0 : day.date, hour: 0 })
                      }}
                    >
                      <div className="xray-user-history-title">
                        <div>
                          <span className="text-muted font-size-14">{new Date(day.date).toLocaleDateString()}</span>{" "}
                          {amountWithCommas(day.clicks)}
                        </div>
                        <div className="xray-user-history-title-line" />
                        <div className="xray-user-history-title-arrow" />
                      </div>
                      <div className="xray-user-history-progress">
                        <div
                          className="xray-user-history-progress-bar"
                          style={{ width: `${(day.clicks / clicksPeriods.day) * 100}%` }}
                        />
                      </div>
                    </div>
                    {visibleHistory.day === day.date && (
                      <div>
                        {day.details.map((hour) => {
                          return (
                            <div
                              key={hour.date}
                              className={classnames("xray-user-history", {
                                "xray-user-history-active": visibleHistory.hour === hour.date,
                              })}
                            >
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  setVisibleHistory({
                                    day: visibleHistory.day,
                                    hour: visibleHistory.hour === hour.date ? 0 : hour.date,
                                  })
                                }}
                              >
                                <div className="xray-user-history-title">
                                  <div>
                                    <span className="text-muted font-size-14">
                                      {new Date(hour.date).toLocaleTimeString()}
                                    </span>{" "}
                                    {amountWithCommas(hour.clicks)}
                                  </div>
                                  <div className="xray-user-history-title-line" />
                                  <div className="xray-user-history-title-arrow" />
                                </div>
                                <div className="xray-user-history-progress">
                                  <div
                                    className="xray-user-history-progress-bar"
                                    style={{ width: `${(hour.clicks / clicksPeriods.hour) * 100}%` }}
                                  />
                                </div>
                              </div>
                              {visibleHistory.hour === hour.date && (
                                <div>
                                  {hour.details.map((minute) => {
                                    return (
                                      <div key={minute.date} className="xray-user-history">
                                        <div className="xray-user-history-title">
                                          <div>
                                            <span className="text-muted font-size-14">
                                              {new Date(minute.date).toLocaleTimeString()}
                                            </span>{" "}
                                            {amountWithCommas(minute.clicks)}
                                          </div>
                                        </div>
                                        <div className="xray-user-history-progress">
                                          <div
                                            className="xray-user-history-progress-bar"
                                            style={{ width: `${(minute.clicks / clicksPeriods.minute) * 100}%` }}
                                          />
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              )}
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}
          {!clicksHistory.length && <div className="text-center py-5 text-muted">No Clicks Yet</div>}
        </div>
      )}
    </div>
  )
}

const groupClicksByDayHourMinute = (clicksData: ApiTypes.AccountClicksHistory[]): DayDetails[] => {
  const result: { [day: number]: DayDetails } = {}

  clicksData.forEach((click) => {
    console.log(click.date)
    const clickDate = new Date(click.date)
    console.log(clickDate.getTime())
    console.log("===============")

    // Extract day, hour, and minute from the click date
    const dayTimestamp = new Date(clickDate.getFullYear(), clickDate.getMonth(), clickDate.getDate()).getTime()
    const hourTimestamp = new Date(
      clickDate.getFullYear(),
      clickDate.getMonth(),
      clickDate.getDate(),
      clickDate.getHours()
    ).getTime()
    const minuteTimestamp = new Date(
      clickDate.getFullYear(),
      clickDate.getMonth(),
      clickDate.getDate(),
      clickDate.getHours(),
      clickDate.getMinutes()
    ).getTime()

    // Group by day
    if (!result[dayTimestamp]) {
      result[dayTimestamp] = {
        date: dayTimestamp,
        clicks: 0,
        details: [],
      }
    }
    result[dayTimestamp].clicks += click.clicks

    // Find or create hour details within the day
    let hourDetails = result[dayTimestamp].details.find((hour) => hour.date === hourTimestamp)
    if (!hourDetails) {
      hourDetails = {
        date: hourTimestamp,
        clicks: 0,
        details: [],
      }
      result[dayTimestamp].details.push(hourDetails)
    }
    hourDetails.clicks += click.clicks

    // Find or create minute details within the hour
    let minuteDetails = hourDetails.details.find((minute) => minute.date === minuteTimestamp)
    if (!minuteDetails) {
      minuteDetails = {
        date: minuteTimestamp,
        clicks: 0,
      }
      hourDetails.details.push(minuteDetails)
    }
    minuteDetails.clicks += click.clicks
  })

  return Object.values(result)
}

export default ClicksHistory
