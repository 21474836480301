// extracted by mini-css-extract-plugin
export var count = "g_L";
export var link = "g_F";
export var linkActive = "g_K";
export var linkGames = "g_J";
export var linkInner = "g_G";
export var linkText = "g_H";
export var menu = "g_w";
export var menuGames = "g_x";
export var menuGamesInner = "g_z";
export var menuGamesLink = "g_B";
export var menuGamesLinkActive = "g_C";
export var menuGamesVisible = "g_y";
export var menuInner = "g_D";